<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Create new domain</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                <v-text-field
                    ref="domainNameInput"
                    v-model=newDomainName
                    label="Domain Name"
                    :rules="newDomainNameRules"
                    validate-on-blur
                    color="green"
                    required
                    hint="The name for the domain"
                    type="text"
                    outlined
                >
                </v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="green white--text" @click="create" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { isValidName, compact } from '@/sdk/input';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    data() {
        return {
            visible: false, // TODO is this really needed? :value @input above maybe all we need
            list: [],
            // create domain
            displayCreateDomain: false,
            newDomainName: null,
            newDomainNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Domain name is required',
            ],
            // add domain
            addDomainChoices: [],
            addDomainId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        isFormComplete() {
            // TODO: check that domain name is not too similar to existing domain name
            return typeof this.newDomainName === 'string' && this.newDomainName.trim().length > 0;
        },
    },

    watch: {
        // displayCreateDomain(value) {
        //     if (value && (this.add || this.create)) {
        //         this.initAddDomainChoices();
        //     }
        // },
        value(newValue) {
            console.log(`dialog new value for v-model: ${newValue}`);
            if (newValue) {
                setTimeout(() => { this.$nextTick(() => this.$refs.domainNameInput.focus()); }, 1);
                this.newDomainName = '';
            }
        },
        // TODO is this 'visible' prop really needed? :value @input above maybe all we need
        visible(value) {
            this.$emit('input', value);
        },
    },

    methods: {
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createDomain();
        },
        cancel() {
            this.$emit('cancel');
        },
        async createDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const request = {
                    // name: this.name,
                    name: this.newDomainName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if domain arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the domain to an appropriate location after domain is created
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.create(request);
                console.log('createDomain response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated) {
                    const domain = { id, ...request };
                    this.list.push(domain);
                    this.$emit('created-domain', domain);
                    this.$emit('added-domain', domain);
                    this.displayCreateDomain = false;
                    this.newDomainName = null;
                } else if (error) {
                    this.error = error;
                } else {
                    this.error = 'Request failed';
                }
                this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
    },
};
</script>
