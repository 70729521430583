<template>
    <div>
        <p>{{ name }}</p>
        <p class="text-caption">{{ status }}</p>
    </div>
</template>
<style>
p {
    margin-bottom: 0px !important;
}
</style>
<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        name() {
            return this.attr.name;
        },
        status() {
            return this.attr.status;
        },
    },

};
</script>
